var Country;
(function (Country) {
    Country["AFGHANISTAN"] = "AF";
    Country["ALBANIA"] = "AL";
    Country["ALGERIA"] = "DZ";
    Country["ANDORRA"] = "AD";
    Country["ANGOLA"] = "AO";
    Country["ANTIGUA_AND_BARBUDA"] = "AG";
    Country["ARGENTINA"] = "AR";
    Country["ARMENIA"] = "AM";
    Country["AUSTRALIA"] = "AU";
    Country["AUSTRIA"] = "AT";
    Country["AZERBAIJAN"] = "AZ";
    Country["BAHAMAS"] = "BS";
    Country["BAHRAIN"] = "BH";
    Country["BANGLADESH"] = "BD";
    Country["BARBADOS"] = "BB";
    Country["BELARUS"] = "BY";
    Country["BELGIUM"] = "BE";
    Country["BELIZE"] = "BZ";
    Country["BENIN"] = "BJ";
    Country["BHUTAN"] = "BT";
    Country["BOLIVIA"] = "BO";
    Country["BOSNIA_AND_HERZEGOVINA"] = "BA";
    Country["BOTSWANA"] = "BW";
    Country["BRAZIL"] = "BR";
    Country["BRUNEI"] = "BN";
    Country["BULGARIA"] = "BG";
    Country["BURKINA_FASO"] = "BF";
    Country["BURUNDI"] = "BI";
    Country["CABO_VERDE"] = "CV";
    Country["CAMBODIA"] = "KH";
    Country["CAMEROON"] = "CM";
    Country["CANADA"] = "CA";
    Country["CENTRAL_AFRICAN_REPUBLIC"] = "CF";
    Country["CHAD"] = "TD";
    Country["CHILE"] = "CL";
    Country["CHINA"] = "CN";
    Country["COLOMBIA"] = "CO";
    Country["COMOROS"] = "KM";
    Country["CONGO"] = "CG";
    Country["COSTA_RICA"] = "CR";
    Country["CROATIA"] = "HR";
    Country["CUBA"] = "CU";
    Country["CYPRUS"] = "CY";
    Country["CZECH_REPUBLIC"] = "CZ";
    Country["DENMARK"] = "DK";
    Country["DJIBOUTI"] = "DJ";
    Country["DOMINICA"] = "DM";
    Country["DOMINICAN_REPUBLIC"] = "DO";
    Country["EAST_TIMOR"] = "TL";
    Country["ECUADOR"] = "EC";
    Country["EGYPT"] = "EG";
    Country["EL_SALVADOR"] = "SV";
    Country["EQUATORIAL_GUINEA"] = "GQ";
    Country["ERITREA"] = "ER";
    Country["ESTONIA"] = "EE";
    Country["ESWATINI"] = "SZ";
    Country["ETHIOPIA"] = "ET";
    Country["FIJI"] = "FJ";
    Country["FINLAND"] = "FI";
    Country["FRANCE"] = "FR";
    Country["GABON"] = "GA";
    Country["GAMBIA"] = "GM";
    Country["GEORGIA"] = "GE";
    Country["GERMANY"] = "DE";
    Country["GHANA"] = "GH";
    Country["GREECE"] = "GR";
    Country["GRENADA"] = "GD";
    Country["GUATEMALA"] = "GT";
    Country["GUINEA"] = "GN";
    Country["GUINEA_BISSAU"] = "GW";
    Country["GUYANA"] = "GY";
    Country["HAITI"] = "HT";
    Country["HONDURAS"] = "HN";
    Country["HUNGARY"] = "HU";
    Country["ICELAND"] = "IS";
    Country["INDIA"] = "IN";
    Country["INDONESIA"] = "ID";
    Country["IRAN"] = "IR";
    Country["IRAQ"] = "IQ";
    Country["IRELAND"] = "IE";
    Country["ISRAEL"] = "IL";
    Country["ITALY"] = "IT";
    Country["IVORY_COAST"] = "CI";
    Country["JAMAICA"] = "JM";
    Country["JAPAN"] = "JP";
    Country["JORDAN"] = "JO";
    Country["KAZAKHSTAN"] = "KZ";
    Country["KENYA"] = "KE";
    Country["KIRIBATI"] = "KI";
    Country["KOREA_NORTH"] = "KP";
    Country["KOREA_SOUTH"] = "KR";
    Country["KOSOVO"] = "XK";
    Country["KUWAIT"] = "KW";
    Country["KYRGYZSTAN"] = "KG";
    Country["LAOS"] = "LA";
    Country["LATVIA"] = "LV";
    Country["LEBANON"] = "LB";
    Country["LESOTHO"] = "LS";
    Country["LIBERIA"] = "LR";
    Country["LIBYA"] = "LY";
    Country["LIECHTENSTEIN"] = "LI";
    Country["LITHUANIA"] = "LT";
    Country["LUXEMBOURG"] = "LU";
    Country["NORTH_MACEDONIA"] = "MK";
    Country["MADAGASCAR"] = "MG";
    Country["MALAWI"] = "MW";
    Country["MALAYSIA"] = "MY";
    Country["MALDIVES"] = "MV";
    Country["MALI"] = "ML";
    Country["MALTA"] = "MT";
    Country["MARSHALL_ISLANDS"] = "MH";
    Country["MAURITANIA"] = "MR";
    Country["MAURITIUS"] = "MU";
    Country["MEXICO"] = "MX";
    Country["MICRONESIA"] = "FM";
    Country["MOLDOVA"] = "MD";
    Country["MONACO"] = "MC";
    Country["MONGOLIA"] = "MN";
    Country["MONTENEGRO"] = "ME";
    Country["MOROCCO"] = "MA";
    Country["MOZAMBIQUE"] = "MZ";
    Country["MYANMAR"] = "MM";
    Country["NAMIBIA"] = "NA";
    Country["NAURU"] = "NR";
    Country["NEPAL"] = "NP";
    Country["NETHERLANDS"] = "NL";
    Country["NEW_ZEALAND"] = "NZ";
    Country["NICARAGUA"] = "NI";
    Country["NIGER"] = "NE";
    Country["NIGERIA"] = "NG";
    Country["NORTH_MAKEDONIA"] = "MK";
    Country["NORWAY"] = "NO";
    Country["OMAN"] = "OM";
    Country["PAKISTAN"] = "PK";
    Country["PALAU"] = "PW";
    Country["PANAMA"] = "PA";
    Country["PAPUA_NEW_GUINEA"] = "PG";
    Country["PARAGUAY"] = "PY";
    Country["PERU"] = "PE";
    Country["PHILIPPINES"] = "PH";
    Country["POLAND"] = "PL";
    Country["PORTUGAL"] = "PT";
    Country["QATAR"] = "QA";
    Country["ROMANIA"] = "RO";
    Country["RUSSIA"] = "RU";
    Country["RWANDA"] = "RW";
    Country["SAINT_KITTS_AND_NEVIS"] = "KN";
    Country["SAINT_LUCIA"] = "LC";
    Country["SAINT_VINCENT_AND_THE_GRENADINES"] = "VC";
    Country["SAMOA"] = "WS";
    Country["SAN_MARINO"] = "SM";
    Country["SAO_TOME_AND_PRINCIPE"] = "ST";
    Country["SAUDI_ARABIA"] = "SA";
    Country["SENEGAL"] = "SN";
    Country["SERBIA"] = "RS";
    Country["SEYCHELLES"] = "SC";
    Country["SIERRA_LEONE"] = "SL";
    Country["SINGAPORE"] = "SG";
    Country["SLOVAKIA"] = "SK";
    Country["SLOVENIA"] = "SI";
    Country["SOLOMON_ISLANDS"] = "SB";
    Country["SOMALIA"] = "SO";
    Country["SOUTH_AFRICA"] = "ZA";
    Country["SOUTH_SUDAN"] = "SS";
    Country["SPAIN"] = "ES";
    Country["SRI_LANKA"] = "LK";
    Country["SUDAN"] = "SD";
    Country["SURINAME"] = "SR";
    Country["SWEDEN"] = "SE";
    Country["SWITZERLAND"] = "CH";
    Country["SYRIA"] = "SY";
    Country["TAIWAN"] = "TW";
    Country["TAJIKISTAN"] = "TJ";
    Country["TANZANIA"] = "TZ";
    Country["THAILAND"] = "TH";
    Country["TOGO"] = "TG";
    Country["TONGA"] = "TO";
    Country["TRINIDAD_AND_TOBAGO"] = "TT";
    Country["TUNISIA"] = "TN";
    Country["TURKEY"] = "TR";
    Country["TURKMENISTAN"] = "TM";
    Country["TUVALU"] = "TV";
    Country["UGANDA"] = "UG";
    Country["UKRAINE"] = "UA";
    Country["UNITED_ARAB_EMIRATES"] = "AE";
    Country["UNITED_KINGDOM"] = "UK";
    Country["UNITED_STATES"] = "US";
    Country["URUGUAY"] = "UY";
    Country["UZBEKISTAN"] = "UZ";
    Country["VANUATU"] = "VU";
    Country["VATICAN_CITY"] = "VA";
    Country["VENEZUELA"] = "VE";
})(Country || (Country = {}));
export default Country;
