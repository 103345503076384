const getLocalTranslation = (localStorageKey) => {
    if (!localStorageKey) {
        return undefined;
    }
    const localStorageItem = localStorage.getItem(localStorageKey);
    const localTranslation = localStorageItem ?
        JSON.parse(localStorageItem) :
        undefined;
    return localTranslation;
};
const setLocalTranslation = (localStorageKey, original, translation, language) => {
    const localStorageTranslation = {
        original,
        translation,
        language,
    };
    try {
        localStorage.setItem(localStorageKey, JSON.stringify(localStorageTranslation));
        return localStorageTranslation;
    }
    catch (_a) {
        return undefined;
    }
};
export { getLocalTranslation, setLocalTranslation, };
