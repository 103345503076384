import * as yup from 'yup';
import { REGEXP_DISPLAY_NAME, REGEXP_USERNAME_SPECIAL_CHAR } from '@app/constants/validation';
export const checkStringEdges = (str) => {
    const firstCharCheck = REGEXP_USERNAME_SPECIAL_CHAR.test(str[0]);
    const lastCharCheck = REGEXP_USERNAME_SPECIAL_CHAR.test(str[str.length - 1]);
    return firstCharCheck === false && lastCharCheck === false;
};
export const publicNameSchema = (translateFn, isRequired) => (publicName) => {
    if ((publicName === null || publicName === void 0 ? void 0 : publicName.length) === 0 && !isRequired)
        return yup.string();
    if (isRequired) {
        return yup.string().trim().min(1, translateFn('profile.messages.publicname_empty'))
            .matches(REGEXP_DISPLAY_NAME, translateFn('profile.messages.publicname_only_latin_and_numbers'))
            .max(30, '');
    }
    return yup.string().matches(REGEXP_DISPLAY_NAME, translateFn('profile.messages.publicname_only_latin_and_numbers')).max(30, '');
};
