import { useEffect, useMemo, useState } from 'react';
import { useLazyDetectTextLanguageQuery, useLazyTranslateTextQuery } from '@store/slices/google-translation/api';
import { IsDefined } from '@utils/js-ts';
import { getLocalTranslation, setLocalTranslation } from './localStorageTranslation';
const useTextTranslation = (text, localStorageKey) => {
    const [textLanguage, setTextLanguage] = useState();
    const [textTranslation, setTextTranslation] = useState();
    const [detectLanguageQuery, { isFetching: detectLanguageIsFetching, isError: detectLanguageIsError, isSuccess: detectLanguageIsSuccess, },] = useLazyDetectTextLanguageQuery();
    const [translateTextQuery, { isFetching: translateTextIsFetching, isError: translateTextIsError, isSuccess: translateTextIsSuccess, },] = useLazyTranslateTextQuery();
    // NOTE: navigator.language return language code with country code (en-US),
    // but GoogleAPI returns only language codes
    // TODO: should equalize both code formats
    const usersLanguage = useMemo(() => navigator.language.split('-')[0] || '', []);
    const canTranslate = IsDefined(usersLanguage) &&
        IsDefined(textLanguage) &&
        usersLanguage !== textLanguage;
    const translate = () => {
        if (text === '') {
            return;
        }
        const localTranslation = getLocalTranslation(localStorageKey);
        if ((localTranslation === null || localTranslation === void 0 ? void 0 : localTranslation.original) === text && localTranslation.translation !== '') {
            setTextTranslation(localTranslation.translation);
        }
        else {
            translateTextQuery({ text, targetLanguageCode: usersLanguage })
                .unwrap()
                .then((data) => {
                setTextTranslation(data.translatedText);
                setLocalTranslation(localStorageKey, text, data.translatedText, textLanguage);
            })
                .catch(() => { });
        }
    };
    // getting textLanguage
    useEffect(() => {
        if (text === '') {
            return;
        }
        const localTranslation = getLocalTranslation(localStorageKey);
        if ((localTranslation === null || localTranslation === void 0 ? void 0 : localTranslation.original) !== text) {
            detectLanguageQuery({ text })
                .unwrap()
                .then((data) => {
                if (IsDefined(data)) {
                    setTextLanguage(data.language);
                    setLocalTranslation(localStorageKey, text, '', data.language);
                }
            })
                .catch(() => { });
        }
        else {
            setTextLanguage(localTranslation.language);
        }
    }, [
        text,
        localStorageKey,
        detectLanguageQuery,
    ]);
    return {
        textLanguage,
        textTranslation,
        canTranslate,
        translate,
        queryState: {
            detectLanguageIsFetching,
            detectLanguageIsError,
            detectLanguageIsSuccess,
            translateTextIsFetching,
            translateTextIsSuccess,
            translateTextIsError,
        },
    };
};
export default useTextTranslation;
