export function IsDefined(value) {
    return value !== null && value !== undefined;
}
export const isEqualShallow = (a, b) => {
    if (!IsDefined(a) && !IsDefined(b)) {
        return true;
    }
    return typeof a === 'object' &&
        typeof b === 'object' &&
        Object.keys(a).length === Object.keys(b).length &&
        Object
            .keys(a)
            .every(key => Object.prototype.hasOwnProperty.call(b, key) &&
            a[key] === b[key]);
};
