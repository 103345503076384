import { jsx as _jsx } from "react/jsx-runtime";
import { ContactProvider } from '@shared/api/profile';
import { ReactComponent as FacebookMainIcon } from '@icons/socials/circle/facebook-circle.svg';
import { ReactComponent as FacebookSmallIcon } from '@icons/socials/mono/facebook-mono.svg';
import { ReactComponent as XMainIcon } from '@icons/socials/circle/x-circle.svg';
import { ReactComponent as XSmallIcon } from '@icons/socials/mono/x-mono.svg';
import { ReactComponent as InstagramMainIcon } from '@icons/socials/circle/instagram-circle.svg';
import { ReactComponent as InstagramSmallIcon } from '@icons/socials/mono/instagram-mono.svg';
import { ReactComponent as DiscordMainIcon } from '@icons/socials/circle/discord-circle.svg';
import { ReactComponent as DiscrodSmallIcon } from '@icons/socials/mono/discord-mono.svg';
import { ReactComponent as TwitchMainIcon } from '@icons/socials/circle/twitch-circle.svg';
import { ReactComponent as TwitchSmallIcon } from '@icons/socials/mono/twitch-mono.svg';
import { ReactComponent as YouTubeMainIcon } from '@icons/socials/circle/youtube-circle.svg';
import { ReactComponent as YouTubeSmallIcon } from '@icons/socials/mono/youtube-mono.svg';
import { ReactComponent as RedditMainIcon } from '@icons/socials/circle/reddit-circle.svg';
import { ReactComponent as RedditSmallIcon } from '@icons/socials/mono/reddit-mono.svg';
import { ReactComponent as TelegramMainIcon } from '@icons/socials/circle/telegram-circle.svg';
import { ReactComponent as TelegramSmallIcon } from '@icons/socials/mono/telegram-mono.svg';
import { ReactComponent as WhatsAppMainIcon } from '@icons/socials/circle/whatsapp-circle.svg';
import { ReactComponent as WhatsAppSmallIcon } from '@icons/socials/mono/whatsapp-mono.svg';
import { ReactComponent as LinkedInMainIcon } from '@icons/socials/circle/linkedin-circle.svg';
import { ReactComponent as LinkedInSmallIcon } from '@icons/socials/mono/linkedin-mono.svg';
import { ReactComponent as TikTokMainIcon } from '@icons/socials/circle/tiktok-circle.svg';
import { ReactComponent as TikTokSmallIcon } from '@icons/socials/mono/tiktok-mono.svg';
import { ReactComponent as WebPageIcon } from '@icons/wolfkit-light/link-light.svg';
import Icon from '@shared/ui/icons/Icon';
const getSocials = (t) => ({
    [ContactProvider.Facebook]: {
        name: t('socials.facebook', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: FacebookMainIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: FacebookSmallIcon }),
    },
    [ContactProvider.X]: {
        name: t('socials.x', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: XMainIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: XSmallIcon }),
    },
    [ContactProvider.Instagram]: {
        name: t('socials.instagram', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: InstagramMainIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: InstagramSmallIcon }),
    },
    [ContactProvider.Discord]: {
        name: t('socials.discord', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: DiscordMainIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: DiscrodSmallIcon }),
    },
    [ContactProvider.Twitch]: {
        name: t('socials.twitch', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: TwitchMainIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: TwitchSmallIcon }),
    },
    [ContactProvider.YouTube]: {
        name: t('socials.youtube', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: YouTubeMainIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: YouTubeSmallIcon }),
    },
    [ContactProvider.Reddit]: {
        name: t('socials.reddit', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: RedditMainIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: RedditSmallIcon }),
    },
    [ContactProvider.Telegram]: {
        name: t('socials.telegram', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: TelegramMainIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: TelegramSmallIcon }),
    },
    [ContactProvider.WhatsApp]: {
        name: t('socials.whatsapp', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: WhatsAppMainIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: WhatsAppSmallIcon }),
    },
    [ContactProvider.LinkedIn]: {
        name: t('socials.linkedin', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: LinkedInMainIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: LinkedInSmallIcon }),
    },
    [ContactProvider.TikTok]: {
        name: t('socials.tiktok', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: TikTokMainIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: TikTokSmallIcon }),
    },
    [ContactProvider.WebPage]: {
        name: t('socials.web_page', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { IconComponent: WebPageIcon }),
        smallIcon: () => _jsx(Icon, { IconComponent: WebPageIcon }),
    },
});
const SocialsUtil = {
    getSocials,
};
export default SocialsUtil;
