const clamp = (n, min, max) => {
    let result = n;
    if (n < min) {
        result = min;
    }
    else if (n > max) {
        result = max;
    }
    return result;
};
const calculatePercentage = (partialValue, totalValue) => {
    if (totalValue === 0) {
        return 0;
    }
    return Math.round((100 * partialValue) / totalValue);
};
const roundToMultipleOf = (n, multiple) => {
    // ensuring that the multiple value is absolute and non-zero
    const multipleValue = multiple === 0 ? 1 : Math.abs(multiple);
    let result = n;
    if (n > 0) {
        result = Math.ceil(n / multipleValue) * multipleValue;
    }
    else if (n < 0) {
        result = Math.floor(n / multipleValue) * multipleValue;
    }
    return result;
};
export { clamp, calculatePercentage, roundToMultipleOf, };
