const maskEmail = (email) => {
    if (!(email === null || email === void 0 ? void 0 : email.length))
        return '';
    const [name, domain] = email.split('@');
    return `${name.slice(0, 2)}***${domain}`;
};
const maskCardNumber = (cardNumber) => {
    var _a;
    const lastChars = (_a = cardNumber.split(' ')) === null || _a === void 0 ? void 0 : _a[3];
    return `${'*'.repeat(7)}${lastChars || ''}`;
};
export { maskEmail, maskCardNumber, };
