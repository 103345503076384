import * as yup from 'yup';
const checkWallet = (translateFn, wallets) => (walletId) => {
    if (walletId.length) {
        const selectedWalletData = wallets.find(({ id }) => id === walletId);
        if (selectedWalletData) {
            const isEnoughAmount = parseFloat(selectedWalletData.totalAssetsUsd) > 100;
            // its necessary to have context here
            // eslint-disable-next-line func-names, consistent-return
            return yup.string().test('', 'check-amount', function () {
                if (!isEnoughAmount) {
                    return this.createError({
                        message: translateFn('portfolio.create_portfolio.wallet_errors.insufficient_amount', { ns: 'common' }),
                    });
                }
                return true;
            });
        }
        return yup.string().length(1, '');
    }
    return yup.string().length(1, '');
};
export { 
// eslint-disable-next-line import/prefer-default-export
checkWallet, };
