import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { RouteNames } from '@app/routing/types';
import { getAbsolutePath } from '@app/routing/helpers';
const useAppNavigation = () => {
    const rrdNavigate = useNavigate();
    const navigate = useCallback((routeName, options = {}) => {
        const path = getAbsolutePath(routeName, options.params);
        if (path) {
            rrdNavigate(path, options);
        }
    }, [rrdNavigate]);
    return {
        routes: RouteNames,
        navigate,
        rrdNavigate,
    };
};
export default useAppNavigation;
