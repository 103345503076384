import Country from './country';
const ENDREX_IMAGES_URL = {
    'endrex-welcome-image': {
        small: '/backgrounds/endrex-welcome-img-small.png',
        medium: '/backgrounds/endrex-welcome-img.png',
    },
    'marketplace-image': {
        small: '',
        medium: '',
        large: '/backgrounds/Wide+Mountain+(NO+Clounds)+3180x1170.png',
    },
};
export default ENDREX_IMAGES_URL;
export { Country };
