const MAX_SIMILARITY = 0.7;
const checkStringsEquality = (firstString, secondString) => {
    const range = Math.min(firstString.length, secondString.length);
    let common = 0;
    for (let i = 0; i < range; i += 1) {
        if (firstString[i] === secondString[i]) {
            common += 1;
        }
    }
    return common / (Math.sqrt(firstString.length * secondString.length));
};
const exceedsMaximumLengthRatio = (password, maxSimilarity, checkingString) => {
    /*
     source code & idea were taken from

     https://github.com/django/django/blob/222bf2932b55ebc964ffc5f9a6f47bad083e5ac2/django/contrib/auth/password_validation.py#L129
    */
    const passwordLength = password.length;
    const lengthBoundSimilarity = (maxSimilarity / 2) * passwordLength;
    const valueLength = checkingString.length;
    return passwordLength >= 10 * valueLength && valueLength < lengthBoundSimilarity;
};
/**
 * check if password equals to email or contain some part of it
 */
export const checkSimilarity = (password, email) => {
    const lowPassword = password.toLowerCase();
    const lowEmailParts = email.toLowerCase().split(/\W+/).concat(email.toLowerCase());
    for (let i = 0; i < lowEmailParts.length; i += 1) {
        if (exceedsMaximumLengthRatio(lowPassword, MAX_SIMILARITY, lowEmailParts[i])) {
            // eslint-disable-next-line no-continue
            continue;
        }
        if (checkStringsEquality(lowPassword, lowEmailParts[i]) >= MAX_SIMILARITY) {
            return false;
        }
    }
    return true;
};
export const checkIfNotNumeric = (password) => ((password === null || password === void 0 ? void 0 : password.length) !== parseInt(password, 10).toString().length);
